:root {
  --lightgrey: #cdced1;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mlr-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.chat-message-stamp {
  margin-top: 10px;
}
.chat-message-video-wrap {
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-align: right;
}
.chat-message-video {
  max-width: 150px;
  height: auto;
}

.chat-message-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.chat-message-image {
  cursor: pointer;

  width: 100%;
  height: 150px;
}
.chat-message-ogp-image {
  cursor: pointer;
  max-height: 130px;
}
.chat-message-ogp-title {
  font-size: 13px;
  color: darkslategrey;
  font-weight: bold;
}
.chat-message-ogp-description {
  font-size: 11px;
  color: darkslategrey;
}
.chat-message-ogp-image-div {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid grey;
}
.chat-message-ogp-image-div:hover {
  text-decoration: underline;
}

.chat-reply-avatar-image {
  border-radius: 40px;
  margin-right: 13px;
  /*margin-top: 26px;*/
}
.chat-avatar-image {
  border-radius: 40px;
  margin-right: 13px;
  margin-top: 26px;
  cursor: pointer;
}

.chat-area-ringing {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.call-popup-buttons-wrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.top-spinner {
  height: 60px !important;
  width: 100% !important;
  margin-bottom: 30px;
  position: initial !important;
}

.messages-area {
  position: relative;
  height: calc(100vh - 180px);
  width: 100%;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  /* background: rgb(247, 248, 252); */
}

.message-wrapper {
  display: flex;
  padding-bottom: 1px;
  padding-top: 1px;
  position: relative;
  transition: transform 600ms;
  justify-content: center;
}
.message-date,
.top-date {
  position: absolute;
  top: -11px;
  text-align: center;
  width: 100%;
}
.message-date span,
.top-date span {
  background: #f7f8fc;
  padding: 0px 8px;
  color: var(--lightgrey);
  font-family: myriad pro regular;
  font-size: 14px;
  border-radius: 14px;
}
.top-date {
  top: 0px;
  width: calc(100% - 27px);
}
.top-date span {
  z-index: 1;
  position: relative;
}
.message-time {
  color: var(--lightgrey);
  font-family: myriad pro regular;
  font-size: 14px;
  margin-top: auto;
  min-width: 60px;
  text-align: center;
  position: relative;
}
.message-in-avatar-sent {
  /*display: flex;*/
  margin-left: auto;
  /*max-width: 60%;*/
  align-items: center;
}
.message-in-avatar-received {
  display: flex;
  margin-right: auto;
  max-width: 80%;
}
.message-options {
  color: var(--lightgrey);
  font-family: myriad pro regular;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: -23px;
}

.message-time-sent {
  margin-left: auto;
}
.message-time-received {
  margin-right: auto;
}
.message {
  padding: 15px;
  font-family: myriad pro regular;
  font-size: 12px;
  background: white;
  /*position: relative;*/
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-line;
  flex: 1;
  max-width: 40vw;
  text-align: left;
}

/* .message::after {
	content: '';
	border: 14px solid transparent;
	border-top-color: #f0f0f0;
	position: absolute;
	top: 0;
  } */
/*
.message-received {
	background: #c7c7d3;
    border-radius: 0 30px 30px 30px;
	color: #67686a;
	box-shadow: grey -1px 7px 16px -4px;
}
.message-sent {
	background: #125ca1;
	border-radius: 30px 0 30px 30px;
	color: #f2f2f2;
	box-shadow: grey -1px 7px 16px -4px;
}*/
.message-status {
  border: 1px solid lightgray;
  border-radius: 15px;
  font-weight: 600;
}
hr {
  margin-bottom: 10px;
  margin-top: 10px;
}
.top-border {
  border-top: 1px solid var(--lightgrey);
}
.call {
  cursor: pointer;
  color: rgb(0, 0, 238);
  text-align: center;
  font-weight: 400;
}
.call:hover {
  text-decoration: underline;
}
.message-bubbler {
  width: 10px;
  height: 100%;
}
.message-bubbler-received {
  background: #125ca1;
}
.message-bubbler-sent {
  background: #c7c7d3;
}
.message-bubbler-cover {
  width: 10px;
  position: absolute;
  background: #f7f8fc;
  z-index: 1;
  height: 100%;
}
.message-bubbler-cover-received {
  border-radius: 0 6px 0 0;
}
.message-bubbler-cover-sent {
  border-radius: 6px 0 0 0;
}

.message-information-wrapper {
  margin: auto;
}
.message-information-wrapper-child {
  background: #c7c7d3;
  border-radius: 30px 30px 30px 30px;
  padding: 5px;
}

.message-options-wrapper-sent {
  text-align: right;
  margin-right: 10px;
  position: relative;
}

.message-options-wrapper-received {
  margin-right: auto;
  position: relative;
}

.message-options-time-sent {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.message-options-time-received {
  text-align: left;
  align-items: center;
  display: flex;
}

.edit-message-wrapper {
  height: 80px;
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
}
.edit-close {
  position: absolute;
  top: -20px;
  left: -20px;
  color: #6ecddd;
  cursor: pointer;
}

.close-button {
  position: absolute;
  right: 10px;
  cursor: pointer;
  border: none;
}

.file-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: myriad pro regular;
  font-size: 14px;
}

.file-container {
  padding: 0 10px;
}

.dialog {
  color: #115ca1;
  font-family: Open Sans;
  background: #f7f8fc !important;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.message-to-delete {
  padding: 15px;
  border: 2px lightgray solid;
  margin: 15px 10px;
  border-radius: 5px;
}

.menu-item {
  font-family: Open sans !important;
  font-size: 14px !important;
}

.relative {
  position: relative;
}

.loadingindicator {
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-download-wraper {
  display: flex;
  align-items: center;
}
.file-download-wraper-message {
  border-bottom: solid 1px;
}
.file-download {
  text-decoration: none;
}
.file-download-sent {
  color: black;
  text-decoration: none;
}
.file-download-received {
  color: black;
  text-decoration: none;
}

.search-message-list {
  height: calc(100vh - 64px - 55px);
  overflow-y: auto;
}
.search-message-time {
  margin-right: 10px;
}
.chat-area-reply-wrap {
  display: flex;
  align-items: center;
}
.chat-area-reply-name {
  color: grey;
  font-weight: bold;
}
.chat-area-reply-top {
  color: grey;
  margin-top: 3px;
  padding-bottom: 5px;
  border-width: 1px;
  border-bottom-style: solid;
}
.chat-area-reply-bot {
  margin-top: 5px;
}

.chat-area-reply-jump {
  cursor: pointer;
}
.reply-list {
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 300px;
}

.message-goodcnt-sent {
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
  cursor: pointer;
  color: palevioletred;
}
.message-goodcnt-sent:hover {
  color: pink;
}

.message-goodcnt {
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
  cursor: pointer;
  color: #515153;
}
.message-goodcnt:hover {
  color: grey;
}

.ojigiman {
  padding: 1px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
}

.message-goodemoji {
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
  cursor: pointer;
  color: #515153;
  /* border-style: solid;
  border-color: #c7c7d3;
  border-width: 2px;
  box-shadow: grey -1px 5px 10px -4px; */
}

.message-goodemoji-selected {
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
  cursor: pointer;
  color: #515153;
  border-style: solid;
  border-color: #c7c7d3;
  border-width: 2px;
  box-shadow: grey -1px 5px 10px -4px;
}

.message-goodemoji:hover {
  background: lightgrey;
}
.message-goodemoji-selected:hover {
  background: lightgrey;
}

.parrent-star-div {
  position: relative;
  z-index: 10;
}
.star-div {
  position: absolute;
  top: 0;
  left: 0;
}
