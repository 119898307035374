/*
Sending area
*/

.sending-area {
  /*display: flex;*/
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: #80808085 2px 4px 10px 0px;
  /* margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 25px; */
}

.sending-area-buttom {
  display: flex;
  justify-content: space-between;
  /*height:50px;*/
  width: 100%;
}

.sending-area-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ecedf0;
}

.reservation-area-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-warning {
  position: absolute;
  right: 0px;
  bottom: -23px;
  font-family: myriad pro regular;
  font-size: 13px;
}
.message-box-wraper {
  width: 100%;
  /* height: 100%; */
  height: calc(100% - 50px);
}
.message-box {
  padding: 10px 10px 10px;
  width: 100%;
  /*height: 50px;*/
  overflow-y: auto;
  background: #f5f6f7;
  border: navajowhite;
  font-family: myriad pro regular;
  font-size: 17px;
  border: none;
  overflow: auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}
.message-box:focus {
  outline: 0px;
}

.message-box::placeholder {
  font-family: myriad pro regular;
  font-size: 19px;
  opacity: 0.6;
}
.file-input {
  width: 50px;
  display: flex;
  align-items: center;
}
.send-button {
  width: 50px;
}
.input-image-preview-grid {
  overflow: auto;
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
  justify-content: center;
}
.input-image-preview-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 200px;
  margin: 15px;
  height: 230px;
  justify-content: space-around;
}

.input-image-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.input-image-preview {
  max-width: 200px;
  max-height: 200px;
  margin: auto 0;
}
.input-single-file {
  display: none;
}

.stamp-box-wrap {
  margin-top: 5px;
  height: 380px;
  width: 360px;
  outline: none !important;
}
.stamp-box-mouseover {
  position: relative;
}
.stamp-box-mouseover-child {
  position: absolute;
  top: 0%;
  right: 0%;
}
.stamp-box {
  display: flex;

  width: 360px;
  flex-wrap: wrap;
}
.stamp-box-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  cursor: pointer;
}
.stamp-box-plus:hover {
  background: #ecedf0;
}
.stamp-box-plus-child {
  padding: 10px;
  object-fit: contain;
}
.stamp-box-child {
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
}
.stamp-box-child:hover {
  background: #ecedf0;
}

.size-over-background {
  height: 100%;
  background: pink;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bot-top-settings {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 500px;
}
.bot-settings {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
}
.input-div-wrap {
  height: 100%;
}
.input-div-reply {
  background: pink;
  height: 30%;
  overflow-y: auto;
}
.input-div-reply-flex {
  display: flex;
  padding: 0 5px;
  align-items: center;
}
.input-div-reply-name {
  flex-shrink: 0;
  padding-top: 3px;
  font-weight: bold;
  align-items: center;
  margin-right: 8px;
}
.input-div-reply-text {
  flex-grow: 1;
  padding-left: 35px;
  align-items: center;
  text-overflow: ellipsis;
}
.input-div-reply-button {
  padding-top: 3px;
  cursor: pointer;
}
