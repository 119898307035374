.chat-search-member {
  height: 35px;
  width: 200px;
  display: flex;
  box-shadow: grey 2px 6px 14px -5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  /*padding-top: 5px;
	padding-bottom: 5px;*/
  border-radius: 100px 100px 100px 100px;
}

.chat-search {
  height: 35px;
  width: 245px;
  display: flex;
  background: #ecedf0;
  box-shadow: grey 2px 6px 14px -5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  /*padding-top: 5px;
	padding-bottom: 5px;*/
  border-radius: 100px 100px 100px 100px;
}
.chat-search-admin {
  height: 35px;
  width: 245px;
  display: flex;
  background: #ecedf0;
  /* box-shadow: grey 2px 6px 14px -5px; */
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  /*padding-top: 5px;
	padding-bottom: 5px;*/
  border-radius: 100px 100px 100px 100px;
}
.chat-search-read {
  height: 35px;
  width: 245px;
  display: flex;
  /* background: #ecedf0; */
  box-shadow: grey 2px 6px 14px -5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  /*padding-top: 5px;
	padding-bottom: 5px;*/
  border-radius: 100px 100px 100px 100px;
}

.chat-search-icon {
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat-search-field {
  width: 65%;
  border: none;
  background: #ecedf0;
  font-family: myriad pro regular;
  font-size: 15px;
}
.chat-search-field::placeholder {
  font-family: myriad pro regular;
  font-size: 15px;
  opacity: 0.6;
}
.chat-search-field:focus {
  outline: 0px;
}

.chat-filter-search-field {
  width: 65%;
  border: none;
  /* background: #ecedf0; */
  font-family: myriad pro regular;
  font-size: 15px;
}
.chat-filter-search-field::placeholder {
  font-family: myriad pro regular;
  font-size: 15px;
  opacity: 0.6;
}
.chat-filter-search-field:focus {
  outline: 0px;
}

.chats-list {
  width: 250px;
  /*height: calc(100vh - 155px);*/
  height: calc(100vh - 64px - 45px);
  overflow-y: auto;
  overflow-x: hidden;
}
/*
.chat-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-shadow: grey -1px 7px 16px -4px;
	margin-bottom: 10px;
	background: #f7f8fc;
	cursor: pointer;
	-webkit-transition: width 0.3s;
	transition: width 0.3s;
	width: 295px;
	color: #9d9d9d;
	position: relative;
	z-index: 0;
}

.chat-wrapper:hover {
	width: 305px;
	color: #e9e8e8;
}

.chat-wrapper:hover .chat-name {
	color: white;
	font-weight: 700;
}
.chat-wrapper:hover .chat-image {
	border: 1px solid white;
}

.chat-wrapper-user {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-shadow: grey -1px 7px 16px -4px;
	margin-bottom: 10px;
	cursor: pointer;
	width: 305px;
	background: #125ca1;
	color: #e9e8e8;
	position: relative;
}

.chat-wrapper-user .chat-name {
	color: white;
	font-weight: 700;
}
*/
.chat-content {
  display: flex;
}

.chat-image {
  border-radius: 100px;
  margin: 3px;
}

.chat-text-wrapper {
  display: flex;
  width: 230px;
  margin-left: 5px;
  font-family: open sans;
  flex-direction: column;
  justify-content: space-evenly;
}
.pinicon-wrapper {
  /* width: 30px; */
  display: flex;
  font-size: 20px;
  /* margin: 0px 10px; */
  font-family: open sans;
  flex-direction: column;
  justify-content: space-evenly;
}

.chat-name-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
}

.chat-name {
  color: #515153;
  font-family: raleway;
  font-weight: 400;
  /*font-size: 19px;*/
  max-width: 149px;
  /* overflow: hidden; */
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.chat-unread {
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background: #3bb890;
  margin-right: 10px;
  margin-bottom: auto;
  margin-top: auto;
}

.chat-specialty {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}

.chat-last-message {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}
.ac-group-between {
  width: 100%;
  display: flex;
  align-items: center;
}
.ac-group {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.ac-group div {
  /*width: 230px;*/
  margin-left: 5px;
  margin-top: 2px;
}

.avatar-item {
  position: relative;
  display: inline-block;
}

.miniroom-unread-badge {
  position: absolute;
  top: 26px;
  left: 26px;
  width: 10px;
  height: 10px;
  line-height: 9px;
  border-radius: 100vh;
  border: solid 1px #fafafa;
}
