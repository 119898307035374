.chat-app {
  height: calc(100vh - 64px);
  overflow: hidden;
  margin-top: -1px;
  display: flex;
  background: #ecedf0;
}
.chat-app-wrap {
  width: calc(100vw - 250px);
  display: flex;
}
.chat-area {
  flex: 0.85;
}
.chatmember {
  flex: 0.15;
}

.logoff-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.alumni-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.show-user-information {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 800;
  font-size: 19px;
  position: absolute;
  top: 90%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.logoff-button {
  margin-left: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.robot-button {
  margin-left: 20px;
  font-size: 1.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
}
