.chat-member-num {
  height: 55px;
  font-family: raleway;
  font-weight: 400;
  font-size: 19px;
  display: flex;
  margin-left: 10px;
  align-items: center;
}
.member-list {
  height: calc(100vh - 110px);
  width: 250px;
  /* height: calc(100vh - 64px - 55px); */
  overflow-y: auto;
}
/*
.member-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-shadow: grey -1px 7px 16px -4px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
	background: #f7f8fc;
	cursor: pointer;
	-webkit-transition: width 0.3s;
	transition: width 0.3s;
	color: #9d9d9d;
	position: relative;
	z-index: 0;
}

.member-wrapper-selected {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-shadow: grey -1px 7px 16px -4px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
	cursor: pointer;
	-webkit-transition: width 0.3s;
	transition: width 0.3s;
	background: #125ca1;
	color: #e9e8e8;
	position: relative;
	z-index: 0;
}

.member-wrapper:hover {
	background: #125ca1;
	color: #e9e8e8;
}

.member-wrapper:hover .member-name {
	color: white;
	font-weight: 700;
}
.member-wrapper:hover .member-image {
	border: 1px solid white;
}*/

.member-text-wrapper {
  display: flex;
  width: 100%;
  font-family: open sans;
  flex-direction: column;
  justify-content: space-evenly;
}
.member-text-vip-wrapper {
  display: flex;
  font-family: open sans;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
}
.member-name-wrapper {
  display: flex;
  justify-content: space-between;
}

.member-search-name-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.member-content {
  display: flex;
  padding-left: 10px;
  cursor: pointer;
  align-items: center;
}
.menu-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
  margin-left: 8px;
}
.miniroom-name-wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.member-miniroom-icon-wrapper {
  /* width: 30px; */
  /* display: flex; */
  display: inline;
  font-size: 18px;
}

#container {
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: 83% 18px 22px;
  flex-direction: column;
  margin-left: 8px;
}

#itemA {
  grid-row: 1/2;
  grid-column: 1/2;
  display: flex;
  line-height: 30px;
}
#itemB {
  grid-row: 1/2;
  grid-column: 2/3;
  display: flex;
  margin-top: 6px;
}
#itemC {
  grid-row: 1/2;
  grid-column: 3/4;
  display: flex;
  margin-top: 6px;
}

.member-image {
  border-radius: 100px;
  /* margin: auto; */
  margin: 5px 10px 5px 5px;
}

.member-name {
  color: #515153;
  font-family: raleway;
  font-weight: 400;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 165px;
}
.member-name-selected {
  color: white;
  font-family: raleway;
  font-weight: 700;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 165px;
}
.member-subname {
  overflow: hidden;
  /*white-space: nowrap;*/
  text-overflow: ellipsis;
  font-size: 12px;
}

.member-search-subname {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-left: 10px;
}
