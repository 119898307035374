

.login-mail {
	display: flex;
	justify-content: center;
}

.login-mail-p{
	text-align:center;
	margin-bottom: 5px;
}

.login-mail-div {
    border-radius: 15px;
    margin: 20px 0;
    background: #eff5f6;
    border: 5px solid #e5eeef;
    padding: 20px;
}
.login-mail-div ol li{
	margin: 10px 0 0 0;
}
.login-initpass-div {
    border-radius: 15px;
    margin: 20px 0;
    background: #eff5f6;
    border: 5px solid #e5eeef;
    padding: 20px;
	display: flex;
	flex-flow: column;
	align-items: center;
}


.error-chat {
	display: flex;
	flex-direction: column;
	
	justify-content: center;
	align-items: center;
	height: -webkit-fill-available;
	height: -moz-available;
	width: 100%;
}

.error-chat > div {
	font-size: 25px;
	line-height: 33px;
	font-weight: 600;
	margin: 20px 0;
	color: var(--docduc-primary);
	font-family: Roboto, Helvetica, sans-serif;
}
.error-chat > img {
	margin: 0 auto;
}
