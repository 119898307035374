.conversation {
  width: 100%;
  /*height: 100%;*/
  /* やっぱり親の高さを設定したほうが良かった */
  height: calc(100vh - 110px);
  /*height:500px;*/

  display: flex;
  flex-flow: nowrap column;
  overflow: hidden;
}

.resizepaneltop {
  display: flex;
  flex-flow: nowrap column;
  justify-content: center;
  align-items: center;

  /*max-height: calc(100vh - 180px);*/

  height: 100%;
  min-height: 200px;
}

.resizepanelbottom {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  height: 100%;
  /*min-height: 200px;*/
}
.editabledivreply {
  background: #f7f8fc;
  padding: 5px;
  border: 2px solid #000000;
  height: 70%;
  width: 100%;
  min-width: 300px;
  max-width: calc(100vw - 500px);
  overflow: auto;
  resize: none;
  display: inline-block;
  box-sizing: border-box;
}
.editablediv {
  background: #f7f8fc;
  padding: 5px;
  border: 2px solid #000000;
  height: 100%;
  width: 100%;
  min-width: 300px;
  max-width: calc(100vw - 500px);
  overflow: auto;
  resize: none;
  display: inline-block;
  box-sizing: border-box;
}

.editablediv img {
  height: auto;
  max-width: 50%;
}
