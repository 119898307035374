.note-class {
  position: relative;
  background-color: ivory;
  top: 50px;
}
.note-toolbar-class {
  position: fixed;
  height: 50px;
  width: 87.5%;
  z-index: 10;
  background-color: ivory;
  border: none !important;
}
.note-toolbar-divider {
  margin-right: 15px;
}
.ssss {
  width: 100px;
}
