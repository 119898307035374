.show-user-image-wraper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.show-user-image-wraper-good {
  position: absolute;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.favor-member-text-wrapper {
  display: flex;
  font-family: open sans;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10px;
}
.rank-member-name-wrapper {
  width: 30px;
  margin-left: 5px;
  display: flex;
}
.favor-count {
  font-size: 15px;
  color: white;
}
.people-count {
  font-size: 15px;
  color: black;
}
.favor-icon {
  font-size: 8px;
  color: #ef4868;
}
.people-icon {
  font-size: 8px;
}
.show-user-image {
  border-radius: 100px;
}

.show-user-image-back {
  width: 400px;
  height: 200px;
  background: black;
  display: inline-block;
}

.show-user-image-back-image {
  width: 100%;
  height: 100%;
  zoom: 1.3;
  opacity: 0.5;
  display: block;
  object-fit: cover;
}
.show-qrcode-image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.show-qrcode-print-image {
  /* width: 100%;
  height: 100%; */
  display: block;
  object-fit: cover;
}
.show-image {
  /* max-width: 100%;
  max-height: 100%; */
  vertical-align: top;
}
.show-video {
  width: 330px;
}

.form1-label {
  width: 70px;
  font-size: 18px;
}
.form1-input {
  box-sizing: border-box;
  width: 300px;

  height: 30px;
  padding: 0 10px;
  margin: 5px 0;
  font-size: 18px;
  color: #333;
  border: solid 1px #ccc;
  border-radius: 6px;
  outline: 0;
  transition: 0.3s;
  -webkit-appearance: none;
}

.form1-input:focus {
  border: 1px solid #00b5ad;
}
.label-input {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.show-member-list {
  /* height: calc(100vh - 64px - 55px); */
  overflow-y: auto;
}

.show-member-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show-chat-search {
  height: 35px;
  width: 250px;
  display: flex;
  background: #ecedf0;
  /* box-shadow: grey 2px 6px 14px -5px; */
  position: relative;
  /*margin-top: 5px;
	margin-bottom: 5px;*/
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 100px 100px 100px 100px;
}
.show-chat-search-icon {
  width: 50px;
  display: flex;
  color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show-create-room-name {
  display: flex;
  position: relative;
}

.show-create-room-field {
  background: #ecedf0;
  font-family: myriad pro regular;
  font-size: 16px;
}
.show-create-room-field::placeholder {
  font-family: myriad pro regular;
  font-size: 16px;
  opacity: 0.6;
}
.show-create-room-field:focus {
  border: none;
}
.plus-background {
  background: pink;
}
/* 安否確認のドラッグ時に、カーソルをニギニギさせる */
.dragHandleSelector {
  cursor: grab;
}
.dragHandleSelector:active {
  cursor: grabbing;
}

.read-member-wrapper:hover {
  background: #e9e8e8;
  cursor: pointer;
}

.read-member-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  background: #f7f8fc;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #9d9d9d;
  position: relative;
  z-index: 0;
}
.read-member-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  cursor: pointer;
  box-shadow: grey -1px 7px 16px -4px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background: #f7f8fc;
  color: black;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  position: relative;
  z-index: 0;
}
.read-member-list-wrapper-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  cursor: pointer;
  box-shadow: grey -1px 7px 16px -4px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background: #125ca1 !important;
  color: #e9e8e8 !important;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  position: relative;
  z-index: 0;
}

.read-member-list-wrapper:hover {
  background: #125ca1 !important;
  color: #e9e8e8 !important;
}

.read-member-content {
  display: flex;
  align-items: center;
}

.anpi-member-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.read-member-image {
  border-radius: 100px;
  /* margin: auto; */
  margin: 5px 10px 5px 5px;
}
.read-member-text-wrapper {
  display: flex;
  font-family: open sans;
  flex-direction: column;
  justify-content: space-evenly;
  /* width: 220px; */
}

.qustion-title-text-wrapper {
  display: flex;
  font-family: open sans;
  flex-direction: column;
  justify-content: space-evenly;
  width: 165px;
}

.read-member-name-wrapper {
  display: flex;
}
.show-read-member-name {
  color: black;
  font-family: raleway;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.read-member-name {
  color: black;
  font-family: raleway;
  font-weight: 400;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 165px;
}
.read-member-subname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.read-member-name-list {
  font-family: raleway;
  /* font-weight: 300; */
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.read-member-read {
  width: 50px;
}

.fontcolor-blue {
  color: blue;
}

.question-title-actions {
  display: flex;
  align-items: center;
}

.question-dialog-wraper {
  display: flex;
}
.question-member-list {
  /*width: 400px;*/
  /*flex: 0.5;*/
  height: calc(100vh - 50px);
  width: 200px;
  /* height: calc(100vh - 70px); */

  overflow-y: auto;
}

.anpi-dialog-wraper {
  display: flex;
}
.anpi-member-list {
  /*width: 400px;*/
  /*flex: 0.5;*/
  /*width: 30vw;*/
  height: calc(100vh - 50px);
  width: 220px;
  overflow-y: auto;
}
.anpi-member-list-right {
  /*width: 400px;*/
  /*flex: 0.5;*/
  /* width: 100%; */
  height: calc(100vh - 50px);
  /* height: calc(100vh - 50px); */
  width: calc(100vw - 220px);
  /* overflow-y: hidden; */
  /* overflow-x: hidden; */
}

.question-member-list-right {
  /*width: 400px;*/
  /*flex: 0.5;*/
  /* width: 70vw; */
  height: calc(100vh - 70px);
  width: calc(100% - 290px);
  overflow-y: hidden;
  overflow-x: hidden;
}
.question-member-list-other {
  /*width: 400px;*/
  /*flex: 1;*/
  /*height: calc(100vh - 320px);*/

  overflow-y: auto;
}

.question-member-right {
  /*width: 400px;*/
  flex: 1 1;
  height: calc(100vh - 46px);
  overflow-y: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px;
}

.question-title-wrap {
  margin: 10px 10px 10px 10px;
  font-size: 22px;
  white-space: pre-wrap;
}
.question-name-wrap {
  text-align: center;
  margin: 5px 5px 5px 5px;
  font-size: 15px;
}
.question-padding-wrap {
  margin-left: 10px;
  /* margin-right: 10px; */
}

.question-create-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.question-create-expire-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  min-width: 400px;
}

.show-user-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
}

.show-user-information-camera {
  position: absolute;
  top: 80%;
  left: 70%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.drop-zone-div-news-wraper {
  width: 300px;
  height: 200px;
  border: 2px dotted #000000;
  text-align: center;
  margin: 8px;
}
.drop-zone-div-wraper {
  width: 300px;
  height: 200px;
  border: 2px dotted #000000;
  text-align: center;
}
.drop-zone-image-div-wraper {
  width: 300px;
  height: 200px;
}
.drop-zone-stamp-div-wraper {
  width: 300px;
  height: 300px;
}

.nivo-chart-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.question-right-title {
  font-size: 25px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.question-member-right-icon {
  z-index: 10;
  position: absolute;
  top: 120px;
  left: 95%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.single-select {
  margin-left: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  min-width: 200px;
}
.multi-select {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  min-width: 200px;
  max-width: 300px;
}
.multi-select-big {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  /* min-width: 500px; */
  width: 100%;
}
.multi-select-mini {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  min-width: 150px;
}
.anpi-single-select {
  margin-left: 12px;
  margin-bottom: 12px;
  margin-top: 10px;
  min-width: 200px;
  max-width: 300px;
}
.date-select {
  max-width: 160px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.full-height {
  height: calc(100vh - 220px);
}

.goodedhonbuid {
  color: palevioletred;
}

.stream {
  width: 480px;
  height: 320px;
}

.video-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-label {
  color: White;
  background: LightGray;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  padding: 10px 0.5em;
  order: -1;
  position: relative;
  z-index: 1;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  flex: 1;
}
.tab-label:not(:last-of-type) {
  margin-right: 5px;
}
.tab-content {
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.user-select-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-select-subarea {
  display: flex;
}
.anpi-subarea {
  display: flex;
  justify-content: start;
  align-items: center;
}
.user-toggle-subarea {
  max-width: 110px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-search-field {
  width: 65%;
  border: none;
  background: #ecedf0;
  font-family: myriad pro regular;
  font-size: 15px;
}
.file-search-field::placeholder {
  font-family: myriad pro regular;
  font-size: 15px;
  opacity: 0.6;
}
.file-search-field:focus {
  outline: 0px;
}
.tile-box {
  margin: 5px;
}
.show-tile-information {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.show-tile-image {
  height: 200px;
  width: 200px;
  object-fit: cover;
  cursor: pointer;
}
.show-tile-file {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bell-area {
  height: calc(100vh - 100px);
  width: 100%;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  background: #ffffff;
}

.news-margin {
  margin: 10px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
