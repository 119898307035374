/*
Chat area header
*/

.chat-image-content{
    margin-right: 3px;
    transition:1s all;
}
.chat-image {
    border-radius: 100px;
    cursor: pointer;
}
.chat-image:hover {
    transform:scale(1.2,1.2);
    transition:0.5s all;
}

.chat-area-header {
	height: 45px;
	display: flex;
	align-items: center;
	background: #ecedf0;
	padding-left: 10px;
	padding-right: 15px;
	justify-content: space-between;
}
.chat-area-header-inner {
    display: flex;
    font-family: raleway;
	font-weight: 400;
	font-size: 19px;
}

.chat-area-header-content {
	display: flex;
}
.chat-area-header-image {
	margin: auto 10px !important;
	border: 1px solid white;
}
.chat-area-header-name {
	font-size: 18px;
	font-family: raleway;
	color: #343434;
}

.chat-area-header-status {
	color: #525353;
	font-size: 13px;
}

.chat-area-header-buttons {
	display: flex;
}

.chat-area-header-buttons img,
.sending-area img,
.sending-area label {
	margin: auto 5px;
	cursor: pointer;
}
